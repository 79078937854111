angular.module('MyHippoCommons.Directives').directive('incentivesModal', function () {
    return {
        scope: {
            title: '<',
            text: '<',
            link: '<',
            noRemindMe: '=',
        },
        restrict: 'E',
        templateUrl: '/modals/incentives/incentives.html',
        controller: function ($scope, $mdDialog, HeapService) {
            $scope.cancel = function (noRemindMe) {
                $mdDialog.hide(noRemindMe);
            };
            $scope.heapTrack = function (noRemindMe) {
                HeapService.trackEvent('incentive_modal_no_remind_me', { 'no_remind_me': noRemindMe });
            };
        },
    };
});
